import React from "react"
import PropTypes from "prop-types"
import "../styles/global.scss"
import "../styles/styles.scss"
import Footer from "./Footer"
import Header from "./Header"
import Notification from "./Notification"

const AppLayout = ({ extraClassName, children }) => {
  return (
    <div className={extraClassName ? `appLayout ${extraClassName}` : `appLayout`}>
      <Notification/>
      <Header />
      {children}
      <Footer/>
    </div>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppLayout
