import {Component} from 'react'
import {Link, NavLink} from "react-router-dom"
import withContext from "./Context"
import {isLoggedIn} from "../utilities/auth"
import {closeWindow} from "../utilities/global"
import logo from "../assets/nude-by-nature-logo.png"
import Icon from "./Icon"
import "./Header.scss"

// noinspection JSIgnoredPromiseFromCall
class Header extends Component {

  componentDidMount() {
    this.storeCheck()
    window.addEventListener('click', this.closeSettingsWindowOnClicks)
    document.getElementById("settings-button").addEventListener('click', this.handleSettingsButtonClick)

    if (isLoggedIn()) {
      window.addEventListener('click', this.closeStoresWindowOnClicks)
      document.getElementById("stores-button").addEventListener('click', this.handleStoreButtonClick)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeSettingsWindowOnClicks)
    document.getElementById("settings-button").removeEventListener('click', this.handleSettingsButtonClick)

    if (isLoggedIn()) {
      window.removeEventListener('click', this.closeStoresWindowOnClicks)
      document.getElementById("stores-button").removeEventListener('click', this.handleStoreButtonClick)
    }
  }

  storeCheck = async () => {
    if (this.props.context.store && this.props.context.store !== "" && document.getElementById("stores-button")) {
      const store = this.props.context.store
      const storesButton = document.getElementById("stores-button")
      storesButton.innerText = store
      storesButton.dataset.value = store.toLowerCase()
    }
  }

  closeStoresWindowOnClicks = (event) => {
    const storesWindow = document.getElementById("stores-window")

    if (storesWindow) {
      // handle other clicks apart from stores or store button
      if (!event.target.classList.contains("store") && event.target.id !== "stores-button") {
        if (storesWindow.style.display !== "none") {
          closeWindow(storesWindow)
        }
      }
    }
  }

  closeSettingsWindowOnClicks = (event) => {
    const settingsWindow = document.getElementById("settings-window")
    // handle other clicks apart from settings or settings button
    if (settingsWindow) {
      if (!event.target.classList.contains("setting") && !event.target.closest("#settings-button")) {
        if (settingsWindow.style.display !== "none") {
          closeWindow(settingsWindow)
        }
      }
    }
  }


  handleStoreButtonClick = async () => {
    const storesWindow = document.getElementById("stores-window")
    const settingsWindow = document.getElementById("settings-window")

    if (settingsWindow.style.display !== "none") {
      closeWindow(settingsWindow)
    }

    if (storesWindow.style.display !== "none") {
      return storesWindow.style.display = "none"
    } else {
      storesWindow.style.display = "block"
    }
  }

  handleSettingsButtonClick = async () => {
    const storesWindow = document.getElementById("stores-window")
    const settingsWindow = document.getElementById("settings-window")

    if (isLoggedIn()) {
      if (storesWindow.style.display !== "none") {
        closeWindow(storesWindow)
      }
    }

    if (settingsWindow.style.display !== "none") {
      return settingsWindow.style.display = "none"
    } else {
      settingsWindow.style.display = "flex"
    }
  }

  render() {
    const {context} = this.props
    const {actions} = context

    /*
     * LOGGED IN RENDER
     */
    if (isLoggedIn()) {
      return (
        <header>
          <div className="logo-container">
            <NavLink to="/app">
              <img className="logo" src={logo} alt="Nude by Nature logo"/>
            </NavLink>
          </div>
          <nav>
            <NavLink exact to="/app" activeClassName="active">Home</NavLink>
            <NavLink to="/app/gwp" activeClassName="active">GWP</NavLink>
            <NavLink to="/app/metafields" activeClassName="active">Metafields</NavLink>
          </nav>
          <div className="options-container">
            <div id="stores-button" className="option">{context.store || "store"}</div>
            <div id="settings-button" className="option">
              <Icon name="gear"/>
            </div>
            <div id="stores-window" className="window stores-window" style={{display: "none"}}>
              <div id="store-au" className="store" data-value="au" onClick={actions.updateStore}>AU</div>
              <div id="store-nz" className="store" data-value="nz" onClick={actions.updateStore}>NZ</div>
              <div id="store-uk" className="store" data-value="uk" onClick={actions.updateStore}>UK</div>
              <div id="store-fr" className="store" data-value="fr" onClick={actions.updateStore}>FR</div>
              <div id="store-de" className="store" data-value="de" onClick={actions.updateStore}>DE</div>
              <div id="store-pl" className="store" data-value="pl" onClick={actions.updateStore}>PL</div>
              <div id="store-ca" className="store" data-value="ca" onClick={actions.updateStore}>CA</div>
              <div id="store-ca-fr" className="store" data-value="ca-fr" onClick={actions.updateStore}>CA-FR</div>
              <div id="store-row" className="store" data-value="row" onClick={actions.updateStore}>ROW</div>
              <div id="store-dev" className="store" data-value="dev" onClick={actions.updateStore}>DEV</div>
            </div>
            <div id="settings-window" className="window settings-window" style={{display: "none"}}>
              <div id="logout" className="setting" onClick={actions.logout}>Log out</div>
            </div>
          </div>
        </header>
      )
    }

    /*
     * DEFAULT RENDER
     */
    return (
      <header>
        <div className="logo-container">
          <Link to="/app">
            <img className="logo" src={logo} alt="Nude by Nature logo"/>
          </Link>
        </div>
        <nav>
          <NavLink to="/app" activeClassName="active">Home</NavLink>
        </nav>
        <div className="options-container">
          <div id="settings-button" className="option">
            <Icon name="gear"/>
          </div>
          <div id="settings-window" className="window settings-window" style={{display: "none"}}>
            <div id="login" className="setting" onClick={() => window.location.pathname = "/login"}>Log in</div>
          </div>
        </div>
      </header>
    )
  }
}

export default withContext(Header)
