import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import Icon from "./Icon"

const Feature = ({linkUrl, name, title, subtitle }) => {
  return (
    <div className="feature">
      <Link className="feature--outer" to={linkUrl}>
        <div className="feature--image">
          <div className="image">
            <Icon name={name}/>
          </div>
        </div>
        <div className="feature--text">
          <div className="text">
            <h6>{title}</h6>
            <p>{subtitle}</p>
          </div>
          <div className="arrow">
            <Icon name="arrow" />
          </div>
        </div>
      </Link>
    </div>
  )
}

Feature.propTypes = {
  linkUrl: PropTypes.string,
  iconName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Feature
