import {createContext, Component} from "react"
import Cookies from "js-cookie"
import Data from "../utilities/data"
import {isLoggedIn} from "../utilities/auth"
import {closeWindow} from "../utilities/global"
import {newNotification} from "../utilities/notification"

const defaultState = {
  data: null,
  gwps: null,
  loggingIn: false,
  products: null,
  store: null,
  user: null,
  actions: {
    getGWPs: () => {},
    setGWP: () => {},
    getProducts: () => {},
    login: () => {},
    logout: () => {},
    updateStore: () => {},
  },
}

const Context = createContext(defaultState)

// noinspection SpellCheckingInspection,JSUnresolvedFunction,ES6MissingAwait,JSIgnoredPromiseFromCall
export class Provider extends Component {
  state = {
    products: null,
    gwps: null,
    store: Cookies.get('store') || null,
    user: Cookies.getJSON('user') || null,
  }

  constructor(props) {
    super(props)
    this.data = new Data()
  }

  componentDidMount() {
  }

  render() {
    const {children} = this.props
    const {gwps, loggingIn, products, store, user} = this.state
    const value = {
      data: this.data,
      gwps,
      loggingIn,
      products,
      store,
      user,
      actions: {
        getGWPs: this.getGWPs,
        setGWP: this.setGWP,
        getProducts: this.getProducts,
        login: this.login,
        logout: this.logout,
        updateStore: this.updateStore,
      },
    }

    return (
      <Context.Provider value={value}>
        {children}
      </Context.Provider>
    )

  }

  login = async (event, username, password) => {
    if (event !== null && event !== undefined) {
      event.preventDefault()
    }

    this.setState({loggingIn: true})

    const user = await this.data.authenticate(username, password)

    if (user !== null) {
      Cookies.set('user', JSON.stringify(user), {expires: 14})
      this.setState({user}, () => {
        if (isLoggedIn()) {
          window.location.pathname = "/app"
        }
      })
    }

    this.setState({loggingIn: false})
    return user
  }

  logout = () => {
    this.setState({user: null})
    Cookies.remove('user')
    window.location.pathname = "/"
  }

  updateStore = async (event) => {
    const storesButton = document.getElementById("stores-button")
    const storesWindow = document.getElementById("stores-window")
    const store = event.target.dataset.value.toUpperCase()
    storesButton.innerText = store
    storesButton.dataset.value = store
    Cookies.set('store', store, {expires: 14})
    this.setState({store: store})
    this.getProducts({store: store})
    this.getGWPs({store: store})
    if (storesWindow.style.display !== "none") {
      closeWindow(storesWindow)
    }
  }

  getProducts = async (body) => {
    if (!isLoggedIn()) {
      return
    }
    this.setState({products: null})
    newNotification("Fetching", `Getting products for ${body.store}`)
    const response = await this.data.api(`/products?store=${body.store.toUpperCase()}`, 'GET')
    if (response.status === 200) {
      newNotification("success", `Products for ${body.store} have been received`)
      return response.json().then(data => {
        this.setState({products: data})
      })
    } else if (response.status === 404) {
      newNotification("error", `No products found for ${body.store}`)
    } else {
      newNotification("error", `Something went wrong getting products ${body.store}`)
    }
  }

  getGWPs = async (body) => {
    if (!isLoggedIn()) {
      return
    }

    this.setState({gwps: null})
    newNotification("Fetching", `Getting GWP offers for ${body.store}`)
    const response = await this.data.api(`/gwps?store=${body.store.toUpperCase()}`, 'GET')
    if (response.status === 200) {
      let {offers} = await response.json()
      // Sort alphabetically by type
      offers.sort((a, b) => {
        if (a.type < b.type) return -1
        return a.type > b.type ? 1 : 0
      })
      this.setState({gwps: offers})
      newNotification("success", `GWP offers for ${body.store} have been received`)
    } else if (response.status === 404) {
      newNotification("error", `No offers found for ${body.store}`)
    } else {
      newNotification("error", `Something went wrong getting GWP offers ${body.store}`)
    }
  }

  setGWP = (index, gwp) => {
    let gwps = this.state.gwps
    gwps[index] = gwp
    this.setState({gwps: gwps})
  }
}

export const Consumer = Context.Consumer

export default function withContext(Component) {
  return function ContextComponent(props) {
    return (
      <Context.Consumer>
        {context => <Component {...props} context={context}/>}
      </Context.Consumer>
    )
  }
}