import React, {Suspense, lazy} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter, Switch, Route} from "react-router-dom"
import {Provider} from "./components/Context"
import App from "./pages/App"
import AppLayout from "./components/AppLayout"
import PrivateRoute from "./components/PrivateRoute"
import './styles/styles.scss'

const Home = lazy(() => import('./pages/Home'))
const GWP = lazy(() => import('./pages/GWP'))
const Login = lazy(() => import('./pages/Login'))
const Metafields = lazy(() => import('./pages/Metafields'))
const NotFound = lazy(() => import('./pages/NotFound'))
const ProductMetafields = lazy(() => import('./pages/ProductMetafields'))

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path={["/app", "/app/gwp", "/app/metafields", "/app/metafields/product"]}>
              <AppLayout>
                <Switch>
                  <PrivateRoute exact path="/app" component={App}/>
                  <PrivateRoute exact path="/app/gwp" component={GWP}/>
                  <PrivateRoute exact path="/app/metafields" component={Metafields}/>
                  <PrivateRoute exact path="/app/metafields/product" component={ProductMetafields}/>
                </Switch>
              </AppLayout>
            </Route>
            <Route component={NotFound}/>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('app')
)