/**
 * Preforms a regex check of an email address against blacklisted email address
 * @param {string} email - Email address to check
 * @return {boolean} - Coerces the regex result to a boolean to be either true or false
 */
export function blacklistedDomains(email) {
  return !!email.match(/(126.com|139.cm|139.com|139.net|163.com|7777.com|pp.com|qq.com|sina.com)$/)
}

/**
 * Sets cookie value on the browser
 * https://stackoverflow.com/questions/1458724/how-do-i-set-unset-a-cookie-with-jquery
 * @param {string} key - Data key
 * @param {string} value - Data value attached to key
 * @param {int} expireDays - Number of days until cookie expires
 */
export function setCookie(key, value, expireDays) {
  if (typeof expireDays === 'undefined') {
    expireDays = 0
  }

  if (expireDays > 0) {
    // Set a cookie that expires, this is set to 24 hour time
    let expires = new Date()
    expires.setTime(expires.getTime() + (expireDays * 24 * 60 * 60 * 1000))
    expires = ";expires=" + expires.toUTCString()
    document.cookie = key + '=' + value + expires + ';path=/'
  }
  else {
    // Set a session only cookie
    document.cookie = key + '=' + value + ';expires=0;path=/'
  }
}

/**
 * Retrieve cookie data
 * @param {string} key - Cookie name
 * @return - Returns data of cookie key or null
 */
export function getCookie(key) {
  const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)')
  return keyValue ? keyValue[2] : null
}

/**
 * Deletes a cookie and its data
 * @param {string} key - Cookie name
 */
export function eraseCookie(key) {
  document.cookie = key + '=; Max-Age=0'
}

/**
 * Checks and returns the object type
 * @param {object} object - The object to check the type of
 * @return {string} - The object type
 */
export function whatIsIt(object) {
  if (object === null) {
    return "null"
  }
  else if (object === undefined) {
    return "undefined"
  }
  else if (object.constructor === String().constructor) {
    return "String"
  }
  else if (object.constructor === Number().constructor) {
    return "Number"
  }
  else if (object.constructor === Boolean().constructor) {
    return "Boolean"
  }
  else if (object.constructor === []) {
    return "Array"
  }
  else if (object.constructor === Object.constructor) {
    return "Object"
  }
  else {
    return "UNKNOWN"
  }
}

/**
 * Replaces the spaces in a string with hyphens
 * @param {string} text
 * @return {string} - Hyphenated string
 */
export function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}

/**
 * Checks to see if Google Analytics has fired on page
 * @return {boolean}
 */
export function check_ga() {
  // could use (typeof ga !== "undefined")
  // noinspection JSUnresolvedVariable
  return typeof ga === 'function'
}

/**
 * Remove the active class on an element and set its display to none
 * @param element
 */
export function closeWindow(element) {
  element.classList.remove("active")
  element.style.display = "none"
}

/**
 * Capitalise the first letter of a string.
 * @param {string} string - The string to capitalise.
 * @return {string}
 */
export function capitaliseFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}