import "./Footer.scss"

const Footer = () =>
  <footer>
    <div>
      <div className="copyright">
        © Nude by Nature {new Date().getFullYear()}
      </div>
    </div>
  </footer>

export default Footer