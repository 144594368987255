const config = {
  apiHost: 'https://api.nudebynature.com/v2',
  // apiHost: 'http://localhost:3000/v2',
  apiPath: '/admin',
}

export default class Data {
  api(path, method = 'GET', body = null, authRequest = false, requiresAuthHeader = false, credentials = null) {

    let url

    if (authRequest)
      url = config.apiHost + path
    else
      url = config.apiHost + config.apiPath + path

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }

    if (body !== null) {
      options.body = JSON.stringify(body)
    }

    if (requiresAuthHeader) {
      const encodedCredentials = btoa(`${credentials.username}:${credentials.password}`)
      options.headers['Authorization'] = `Basic ${encodedCredentials}`
    }

    return fetch(url, options)
  }

  async authenticate(username, password) {
    const response = await this.api(`/authenticate`, 'GET', null, true, true, {username, password})
    if (response.status === 200) {
      return response.json().then(data => data)
    } else if (response.status === 401) {
      return null
    } else {
      throw new Error()
    }
  }
}