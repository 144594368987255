import React from "react"
import {Redirect} from "react-router-dom"
import {isLoggedIn} from "../utilities/auth"

const PrivateRoute = ({component: Component, location, pathname, ...rest}) => {
  if (!isLoggedIn() && pathname !== `/login`) {
    return <Redirect to="/login" />
  }

  return <Component {...rest} />
}

export default PrivateRoute