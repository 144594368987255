function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1)
}

const closeNotificationEventHandler = (event) => {
  collapseSection(event.target.closest('.notification'))
}

const createNotificationElement = (type, message) => {
  const newElement = document.createElement('div')
  // noinspection JSValidateTypes
  newElement.style = "margin-bottom: 0; padding: 0 20px; height: 0;"  // For transition
  newElement.classList.add('notification')
  if (type === "error" || type === "success") {
    newElement.classList.add(type)
  }
  newElement.setAttribute('data-collapsed', 'true')
  newElement.addEventListener('click', closeNotificationEventHandler)

  const inner = document.createElement('div')
  inner.className = 'inner'

  const titleElement = document.createElement('h2')
  titleElement.innerText = capitalizeFirstLetter(type)
  inner.appendChild(titleElement)

  const messageElement = document.createElement('p')
  messageElement.classList.add('inner')
  messageElement.innerHTML = message
  inner.appendChild(messageElement)

  newElement.appendChild(inner)

  return newElement
}

const collapseSection = (element) => {
  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(function() {
    element.style.height = 0 + 'px'
    element.style.marginBottom = 0 + 'px'
    element.style.padding = '0 20px'
  })

  // mark the section as "currently collapsed"
  element.setAttribute('data-collapsed', 'true')
  setTimeout(function() {
    element.remove()
  }, 600)
}

const expandSection = (element) => {
  const sectionHeight = element.scrollHeight
  requestAnimationFrame(function() {
    element.style.height = (sectionHeight + 20) + 'px'
    element.style.marginBottom = '10px'
    element.style.padding = '10px 20px'
  })

  element.setAttribute('data-collapsed', 'false')

  setTimeout(function() {
    collapseSection(element)
  }, 3000)
}

const newNotification = (type, message) => {
  const newNotification = createNotificationElement(type, message)
  document.getElementById('notification-wrapper').appendChild(newNotification)
  expandSection(newNotification)
}

export {
  createNotificationElement,
  expandSection,
  collapseSection,
  newNotification,
}