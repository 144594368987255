import Cookies from 'js-cookie'

// noinspection JSUnresolvedFunction
/**
 * Get the user from cookies.
 */
export const getUser = () => Cookies.getJSON("user") !== undefined ? Cookies.getJSON("user") : {}

// noinspection JSUnresolvedFunction
/**
 * Set the user in cookies.
 */
export const setUser = user => Cookies.set('user', JSON.stringify(user), {expires: 1})

/**
 * Check if the user is logged in.
 *
 * This isn't a proper authentication check, the API needs to be secured. JWT would be a good implementation.
 */
export const isLoggedIn = () => {
  const user = getUser()
  return !!user.username
}