import "./App.scss"
import Feature from "../components/Feature"
import {isLoggedIn} from "../utilities/auth"

const App = () => {
  return (
    <div className="content">
      <div className="m">
        <div className="intro">
          <h1 className="obvious">Home.</h1>
        </div>
        <div className="features-section">
          {isLoggedIn() ? (
            <>
              <h3>Tools</h3>
              <div className="features">
                <Feature
                  linkUrl="/app/gwp"
                  name="sale"
                  title="GWP"
                  subtitle="Update GWP offers"
                />
                <Feature
                  linkUrl="/app/metafields"
                  name="metafields"
                  title="Metafields"
                  subtitle="Update metafields"
                />
              </div>
            </>
          ) : (
            <h3 onClick={() => window.location.pathname = "/login"}>Please log in.</h3>
          )}
        </div>
      </div>
    </div>
  )
}

export default App